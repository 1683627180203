<template>
  <div>
    <HomeSwiper @search="search" src="/images/banner.jpg" placeholder-text="搜索产品"></HomeSwiper>

    <div class="container">
      <div class="txt">
        <h2>2024新疆农业机械博览会组委会联系方式：0991-4656071</h2>
        <img src="/images/2023/QR.jpg" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper";

export default {
  components: {
    HomeSwiper,
  },
  methods: {
    search(searchText) {
      window.location.href = `/cms/goods?keyword=${searchText}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1280px;
  margin: auto;
  padding: 20px;
  .txt {
    text-align: center;
  }
  .image {
    max-width: 50%;
  }
}
</style>
